.nav {
  background-color: var(--theme-color);
  height: var(--header-height);
  font-display: swap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: Lato, sans-serif;
  display: flex;
  position: fixed;
  top: 0;
}

.nav-section {
  min-width: 210px;
  height: 30px;
  max-height: 30px;
  margin: 0 25px;
}

.nav-left {
  align-items: center;
  display: flex;
}

.nav-middle a {
  align-items: center;
  height: 30px;
  max-height: 30px;
  text-decoration: none;
  display: flex;
}

.nav-middle a:hover, .nav-middle a:focus {
  color: #0000;
  background-color: #0000;
  text-decoration: none;
}

.nav-right {
  text-align: right;
}

.btn-donate {
  white-space: normal;
  cursor: pointer;
  color: var(--theme-color);
  background-color: #feac32;
  background-image: linear-gradient(#fecc4c, #ffac33);
  border: 3px solid #feac32;
  outline: 0;
  margin: 0 auto;
  padding: 0 14px;
  font-family: Lato, sans-serif;
  text-decoration: none;
  display: inline-block;
}

.btn-donate:hover, .btn-donate:focus, .btn-donate:active:hover {
  color: var(--theme-color);
  background-color: #fecc4c;
  background-image: none;
  border-width: 3px;
  border-color: #f1a02a;
  text-decoration: none;
}

.btn-donate:active {
  background-image: none;
  text-decoration: none;
  box-shadow: inset 0 1px 4px #0000004d;
}

@media (width <= 780px) {
  .nav-left {
    display: none;
  }

  .nav-section {
    min-width: 0;
  }
}

@media (width <= 400px) {
  .nav-section {
    margin: 0 5px;
  }
}

.logo-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.btn-main {
  border: 1px solid var(--primary-color);
  color: var(--quaternary-color);
  background-color: var(--primary-background);
}

.btn-main:hover {
  background-color: var(--primary-color);
  fill: #fff;
}

.btn-main:hover h2 {
  color: #fff;
}

.main {
  min-height: calc(100vh - var(--header-height));
  width: 780px;
  max-width: 100%;
  margin: 0 auto;
}

@media (width >= 1280px) {
  .main {
    width: 1080px;
  }
}

.main h1 {
  text-align: center;
}

section {
  max-width: 90%;
  margin: 0 auto;
}

.ul-main {
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: calc(25px + 3vw);
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding-left: 0;
  display: flex;
}

.li-main {
  justify-content: center;
  align-items: center;
  width: 50%;
  height: calc(250px + 6vw);
  max-height: 300px;
  list-style: none;
  display: flex;
}

.li-main-web3 {
  flex-basis: 100%;
}

.btn-main {
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  text-decoration: none;
  display: flex;
}

.btn-main h2 {
  margin-bottom: 0;
  font-size: 1.5em;
}

.home-logo-wrap {
  width: 100%;
}

.home-logo-wrap svg {
  height: 100px;
  margin-bottom: 24px;
}

@media (width <= 780px) {
  .li-main {
    width: 100%;
  }

  .ul-main {
    row-gap: 0;
  }
}

.superblock-logo-wrap {
  width: 250px;
  margin: 0 auto;
}

.superblock-logo-wrap h1 {
  margin-top: 70px;
}

.project-wrap {
  background-color: #fff;
  margin-bottom: 5px;
  padding: 20px 25px;
}

.prerequisites li {
  margin-bottom: 5px;
}

.instructions li {
  margin-bottom: 15px;
}

@media (width <= 500px) {
  .superblock-logo-wrap {
    width: 200px;
    margin: 0 auto;
  }

  .superblock-logo-wrap h1 {
    margin-top: 50px;
  }
}

@font-face {
  font-family: Lato;
  src: url("Lato-Light.0a32ada5.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Regular.c8723e06.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Italic.cd7bad63.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Bold.92779cbe.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Black.53ad01e8.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Roboto Mono;
  src: url("RobotoMono-Regular.19e21f20.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Roboto Mono;
  src: url("RobotoMono-Italic.732e2498.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: Roboto Mono;
  src: url("RobotoMono-Bold.81d25811.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

:root {
  --header-height: 38px;
  --section-spacer: calc(20px + 4vw);
  --theme-color: #0a0a23;
  --yellow-gold: #ffbf00;
  --gray-00: #fff;
  --gray-05: #f5f6f7;
  --gray-10: #dfdfe2;
  --gray-15: #d0d0d5;
  --gray-45: #858591;
  --gray-75: #3b3b4f;
  --gray-80: #2a2a40;
  --gray-85: #1b1b32;
  --gray-90: #0a0a23;
  --purple-light: #dbb8ff;
  --purple-dark: #5a01a7;
  --yellow-light: #ffc300;
  --yellow-dark: #4d3800;
  --blue-light: #99c9ff;
  --blue-light-translucent: #99c9ff4d;
  --blue-dark: #002ead;
  --blue-dark-translucent: #002ead4d;
  --green-light: #acd157;
  --blue-mid: #198eee;
  --purple-mid: #9400d3;
  --green-dark: #00471b;
  --red-light: #ffadad;
  --red-dark: #850000;
  --love-light: #f8577c;
  --love-dark: #f82153;
  --editor-background-light: #fffffe;
  --editor-background-dark: #2a2b40;
}

.dark-palette {
  --primary-color: var(--gray-00);
  --secondary-color: var(--gray-05);
  --tertiary-color: var(--gray-10);
  --quaternary-color: var(--gray-15);
  --quaternary-background: var(--gray-75);
  --tertiary-background: var(--gray-80);
  --secondary-background: var(--gray-85);
  --primary-background: var(--gray-90);
  --highlight-color: var(--blue-light);
  --highlight-background: var(--blue-dark);
  --selection-color: var(--blue-light-translucent);
  --success-color: var(--green-light);
  --success-background: var(--green-dark);
  --danger-color: var(--red-light);
  --danger-background: var(--red-dark);
  --love-color: var(--love-light);
  --editor-background: var(--editor-background-dark);
}

.light-palette {
  --primary-color: var(--gray-90);
  --secondary-color: var(--gray-85);
  --tertiary-color: var(--gray-80);
  --quaternary-color: var(--gray-75);
  --quaternary-background: var(--gray-15);
  --tertiary-background: var(--gray-10);
  --secondary-background: var(--gray-05);
  --primary-background: var(--gray-00);
  --highlight-color: var(--blue-dark);
  --highlight-background: var(--blue-light);
  --selection-color: var(--blue-dark-translucent);
  --success-color: var(--green-dark);
  --success-background: var(--green-light);
  --danger-color: var(--red-dark);
  --danger-background: var(--red-light);
  --love-color: var(--love-dark);
  --editor-background: var(--editor-background-light);
}

* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  scroll-padding-top: calc(var(--header-height) * 1.4);
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

body {
  color: var(--secondary-color);
  background-color: var(--secondary-background);
  height: 100%;
  margin: 0;
  padding: 38px 0 0;
  font-family: Lato, sans-serif;
}

#root {
  background-color: var(--secondary-background);
}

.page-wrapper {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

.codeally-frame {
  height: calc(100vh - var(--header-height, 0px));
  border: 0;
  width: 100%;
  display: block;
}

.btn-cta-big {
  font: Lato;
  white-space: normal;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  margin: 0 auto;
  padding: 5px;
  font-size: 1.5rem;
}

.overflow-fix {
  margin-left: 0;
  margin-right: 0;
}

.default-layout {
  margin-top: var(--header-height, 0);
  background: var(--secondary-background);
  flex: 1 0 auto;
}

h1 {
  color: var(--secondary-color);
  margin: .6rem 0;
  font-family: Roboto Mono, monospace;
  font-size: 3.8rem;
  font-weight: 700;
}

h2 {
  color: var(--secondary-color);
  margin: .6rem 0;
  font-family: Roboto Mono, monospace;
  font-size: 1.7rem;
  font-weight: 700;
}

h3 {
  color: var(--secondary-color);
  margin: 0 0 .6rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.1rem;
  font-weight: 700;
}

h4, h5, h6, p, td, th {
  color: var(--secondary-color);
  margin: 0 0 1.2rem;
  font-family: Roboto Mono, monospace;
  font-size: 1rem;
  font-weight: 400;
}

p, td, th {
  font-family: Lato, sans-serif;
}

p {
  line-height: 1.5rem;
}

.big-heading {
  overflow-wrap: break-word;
  font-size: 2.5rem !important;
}

::selection {
  background-color: var(--selection-color);
}

@media (width <= 500px) {
  .big-heading {
    font-size: 1.5rem !important;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .btn-cta, .btn-cta-big {
    font-size: 1rem;
  }
}

@media (width <= 1199px) {
  .btn-cta-big {
    font-size: 1.3rem;
  }
}

.text-center {
  text-align: center !important;
}

.green-text {
  color: var(--secondary-color);
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover, a:focus {
  color: var(--tertiary-color);
  background-color: var(--tertiary-background);
  text-decoration: none;
}

.fcc-modal {
  background-color: var(--secondary-background);
  color: var(--secondary-color);
}

button.close {
  color: inherit;
  opacity: .5;
  text-shadow: none;
  background-color: #0000;
  font-size: 28px;
}

button.close:hover, button.close:focus {
  color: inherit;
  opacity: 1;
  background-color: #0000;
}

.modal-content {
  background-color: var(--secondary-background);
  border: 1px solid var(--secondary-color);
  border-radius: 0;
}

.modal-footer, .modal-header {
  border-color: var(--secondary-color);
}

button, .btn, input[type="submit"] {
  background-color: var(--quaternary-background);
  border-width: 3px;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  white-space: pre-line;
  border-radius: 0;
  text-decoration: none;
}

button:hover, .btn:hover, .btn-default.active:hover, .btn-default.active, .btn-primary:hover, input[type="submit"]:hover {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--secondary-background);
  cursor: pointer;
}

.btn:active, .btn-primary:active {
  background-color: var(--secondary-background);
}

.btn:focus, .btn-primary:focus, .btn-primary.focus {
  background-color: var(--quaternary-background);
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn:active:hover, .btn-primary:hover, .btn-primary:active:hover, .btn-primary.active:hover, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:active:focus, .btn-primary.active:focus, .open > .dropdown-toggle.btn-primary:focus, .btn-primary:active.focus, .btn-primary.active.focus, .open > .dropdown-toggle.btn-primary.focus {
  background-color: var(--secondary-color);
  color: var(--secondary-background);
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus {
  cursor: not-allowed;
  background-color: var(--quaternary-background);
  border-color: var(--quaternary-color);
  color: var(--quaternary-color);
}

.btn-cta {
  background-color: #feac32;
  background-image: linear-gradient(#fecc4c, #ffac33);
  border-width: 3px;
  border-color: #feac32;
  color: #0a0a23 !important;
}

.btn-cta:hover, .btn-cta:focus, .btn-cta:active:hover {
  background-image: none;
  border-width: 3px;
  border-color: #f1a02a;
  color: #0a0a23 !important;
  background-color: #fecc4c !important;
}

.btn-cta:active {
  background-image: none;
  box-shadow: inset 0 1px 4px #0000004d;
}

.btn-link, .btn-link:focus, .btn-link:active {
  color: inherit;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  text-decoration: underline;
}

.btn-link:hover, .btn-link:focus:active {
  background: var(--tertiary-background);
  color: inherit;
  text-decoration: none;
}

.link-btn {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 1rem;
  display: flex;
}

.link-btn.btn-lg svg {
  min-width: 16px;
  height: 100%;
  min-height: 20px;
  margin-left: 5px;
}

.link-btn:active {
  background-color: var(--quaternary-background);
}

.link-btn:focus, .link-btn.btn-lg:focus svg {
  fill: var(--quaternary-background);
  background-color: var(--secondary-color);
  border-color: var(--gray-45);
  color: var(--secondary-background);
}

.link-btn:focus:not(:focus-visible), .link-btn.btn-lg:focus:not(:focus-visible) svg {
  background-color: var(--quaternary-background);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  fill: var(--primary-color);
}

.link-btn:hover, .link-btn.btn-lg:hover svg {
  fill: var(--quaternary-background) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: var(--secondary-background) !important;
}

.link-btn.btn-lg svg, .map-icon, .cert-header-icon {
  fill: var(--primary-color);
  flex-shrink: 0;
}

.map-icon {
  width: 35px;
  max-height: 45px;
  margin-right: 20px;
}

.cert-header-icon {
  width: 80px;
  margin: auto;
  display: block;
}

@media (width >= 700px) {
  .link-btn {
    font-size: 1.1rem;
  }

  .map-icon {
    width: 45px;
    max-height: 55px;
  }

  .cert-header-icon {
    width: 100px;
  }
}

@media (width >= 1000px) {
  .map-icon {
    width: 50px;
  }

  .cert-header-icon {
    width: 120px;
  }
}

.button-group .btn:not(:last-child) {
  margin-bottom: 10px;
}

strong {
  color: var(--secondary-color);
}

.form-control {
  color: var(--primary-color);
  border-color: var(--quaternary-background);
  background-color: var(--primary-background);
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

input {
  border-color: var(--quaternary-background);
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

textarea {
  resize: vertical;
}

form {
  margin-bottom: 5px;
}

.form-control:focus, input:focus {
  border-color: var(--tertiary-color);
}

code {
  background-color: var(--quaternary-background);
  color: var(--tertiary-color);
  overflow-wrap: anywhere;
  border-radius: 0;
  font-family: Roboto Mono, monospace;
}

.panel {
  background-color: #0000;
  border-radius: 0;
}

.help-block em {
  font-size: .8rem;
}

hr {
  border-top: 1px solid var(--quaternary-background);
}

.panel-default {
  background: var(--secondary-background);
}

.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary, .open > .dropdown-toggle.btn-primary:hover {
  background-color: var(--secondary-color);
  color: var(--secondary-background);
}

.open > .dropdown-menu {
  border-radius: 0;
}

.dropdown-menu {
  background-color: var(--secondary-color);
}

.dropdown-menu > li > a {
  color: var(--secondary-background);
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: var(--secondary-color);
  background-color: var(--secondary-background);
}

.table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: var(--tertiary-background);
}

.challenge-instructions table {
  display: inline-block;
  overflow: auto;
}

.challenge-instructions table thead {
  vertical-align: middle;
  border-color: inherit;
  display: table-header-group;
}

.challenge-instructions table th {
  font-weight: 700;
}

.challenge-instructions table th, .challenge-instructions table td {
  border: 1px solid var(--secondary-color);
  text-align: center;
  padding: 6px 13px;
}

.help-block {
  color: var(--quaternary-color);
}

.panel-default > .panel-heading {
  background-color: #0000;
}

.challenge-output span {
  font-size: 1rem;
}

.breadcrumb {
  background: var(--tertiary-background);
  border-radius: 0;
}

code[class*="language-"], pre[class*="language-"] {
  border-radius: 0;
}

pre {
  color: inherit;
  background-color: inherit;
  border: none;
  border-radius: 0;
}

.list-group-item {
  background: none;
  border: none;
  border-radius: 0;
}

.intro-toc .list-group-item:hover {
  border-radius: 0;
  background-color: var(--tertiary-background) !important;
}

.panel-primary > .panel-heading {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: #0000;
}

.solution-viewer, .solution-viewer pre {
  margin-bottom: 0;
}

.solution-viewer {
  border-color: var(--primary-color);
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: var(--highlight-color);
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: var(--danger-color);
}

.has-error .form-control, .has-warning .form-control, .has-success .form-control {
  border-color: var(--quaternary-background);
}

.has-error .form-control:focus, .has-warning .form-control:focus, .has-success .form-control:focus {
  border-color: var(--tertiary-color);
}

.panel-primary {
  border-color: var(--primary-color);
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: var(--secondary-background);
}

.nav-tabs > li > a {
  border-radius: 0;
}

blockquote footer, blockquote small, blockquote .small {
  color: var(--gray-45);
}

.alert {
  overflow-wrap: break-word;
  border-radius: 0;
}

.alert .btn {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #31708f;
}

.alert .btn:hover, .alert .btn:focus, .alert a:hover, .alert a:focus {
  color: #d9edf7;
  background-color: #31708f;
  border-color: #31708f;
}

#search {
  background-color: var(--quaternary-background);
  color: var(--primary-color);
  border-color: var(--quaternary-color, buttonface);
  border-style: outset;
  border-width: 2px;
  border-image: initial;
}

#search::-webkit-input-placeholder {
  color: var(--secondary-color);
}

#search::placeholder {
  color: var(--secondary-color);
}

.sr-only {
  color: #fff;
  background-color: #000;
}
/*# sourceMappingURL=index.6c058ca1.css.map */
